<template>
  <b-card
    no-body
    class="card-developer-meetup border"
  >
    <div class="rounded-top text-center m-50 p-0">
      <!-- <b-img
        :src="require('@/assets/images/email.svg')"
        alt="Meeting Pic"
        height="135"
      /> -->
      <CarouselForImages
        style="max-height: 100%; max-width:100%"
        :images="venue.images"
        :card-venue="true"
      />
    </div>
    <b-card-body
      class="d-flex flex-column justify-content-between"
    >
      <!-- metting header -->
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <div class="meetup-day played1-tag-class">
          <div class="mb-0 font-small-1">
            CPM: $ <span class="font-small-1 font-weight-bolder">
              {{ venue.averageCpm ? (venue.averageCpm).toFixed(1) : 0 }}
            </span>
          </div>
        </div>
        <div class="meetup-day played1-tag-class">
          <div class="mb-0 font-small-1">
            Impressions: <span class="font-small-1 font-weight-bolder">{{ venue.totalImpressionOfVenue }}</span>
          </div>
        </div>
      </div>
      <!--/ metting header -->
      <div class="d-flex flex-column justify-content-between">
        <b-card-title class="mb-25 font-medium-1 font-weight-bolder">
          {{ venue.venue_name }}
        </b-card-title>
        <b-card-title class="mb-75 font-small-1 pt-2">
          {{ venue.address }}
        </b-card-title>
      </div>

      <!-- avatar group -->
      <div class="mt-2 d-flex justify-content-between">
        <b-button
          size="sm"
          variant="primary"
          :disabled="checkIfVenueIsSelected(venue.id)"
          @click="bookThisVenue"
        >
          {{ checkIfVenueIsSelected(venue.id) ? 'Booked' : 'Book Now' }}
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="goToSelectedVenue(venue.id)"
        >
          View
        </b-button>
      </div>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  //  BImg,
  BCardBody, BCardTitle,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import CarouselForImages from './CarouselForImages.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    // BImg,
    BCardBody,
    BCardTitle,
    BButton,
    CarouselForImages,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    venue: {
      type: Object,
      default: () => null,
    },
    selectedVenue: {
      type: Array,
      default: () => null,
    },
    simiNetwork: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },
  methods: {
    bookThisVenue() {
      this.$emit('bookThisVenue')
    },
    checkIfVenueIsSelected(id) {
      return this.selectedVenue.some(screen => screen.id === id)
    },
    goToSelectedVenue(venueId) {
      if (this.$route.name === 'simi-network') {
        this.$router.push(`/simi-network/venue/${venueId}`)
      } else if (this.$route.name === 'dashboard') {
        this.$router.push(`/dashboard/venue/${venueId}`)
      } else if (this.$route.name === 'user') {
        this.$router.push({
          name: 'user-single-venue-details',
          params: {
            venueId,
            userId: this.$route.params.userId,
          },
        })
      }
    },
  },
}
</script>

<style scoped>

.card-body{
  padding: 0.5rem !important;
}
.played1-tag-class{
  padding: 2px 5px !important;
}
.card-developer-meetup{
  min-height: 380px !important;
}
</style>

<template>
  <div>
    <b-row>
      <b-col
        lg="6"
        md="6"
        class="mb-2"
      >
        <b-skeleton-img
          v-if="getVenueDataLoader"
          no-aspect
          height="300px"
        />
        <CardEarningReports
          v-else
          :stats-data="dataForAgency ? dataForAgency.agencyEarning : null"
          :date-sequence="agencyDateSequence"
          :earning-sequence="agencyEarningSequence"
        />
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-skeleton-img
          v-if="getVenueDataLoader"
          no-aspect
          height="300px"
        />
        <CardStats
          v-else
          :stats-data="dataForAgency ? dataForAgency : null"
        />
      </b-col>
    </b-row>
    <b-tabs
      pills
      nav-class="nav-pill-primary d-flex justify-content-center mb-4"
    >
      <b-tab
        title="Venue"
        active
      >
        <b-card>
          <CardAgencyVenueDashboard
            class="p-25"
          />
        </b-card>
      </b-tab>
      <b-tab
        title="Network"
        disabled
      />
    </b-tabs>
    <b-row>
      <b-col>
        <ShowVenueList
          :is-coming-from-dashboard="true"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BSkeletonImg,
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue'
import CardEarningReports from '@/components/common/CardEarningReports.vue'
import CardAgencyVenueDashboard from '@/components/common/CardAgencyVenueDashboard.vue'
import CardStats from '@/components/account-setting/CardStats.vue'
import ShowVenueList from '@/components/simiNetwork/ShowVenueList.vue'
import { showToast } from '@/common/global/functions'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import AccountTypes from '@/common/enums/accountTypeEnum'

export default {
  components: {
    BSkeletonImg,
    CardEarningReports,
    BTabs,
    BTab,
    CardStats,
    CardAgencyVenueDashboard,
    ShowVenueList,
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      getVenueDataLoader: false,
      dataForAgency: null,
    }
  },
  computed: {
    isSimmiUser() {
      return this.$store.getters['user/isSimmiUser']
    },
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(MemberPermissions.AGENCY)
    },
    agencyDateSequence() {
      return this.dataForAgency?.agencyEarning ? this.dataForAgency.agencyEarning.last7DaysEarningsArray.map(item => item.date) : []
    },
    agencyEarningSequence() {
      return this.dataForAgency?.agencyEarning ? this.dataForAgency.agencyEarning.last7DaysEarningsArray.map(item => item.earning) : []
    },
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },
  async mounted() {
    this.getDataforAgencyDashboard()
  },
  methods: {
    async getDataforAgencyDashboard() {
      let response
      try {
        this.getVenueDataLoader = true
        if (this.isUserAdmin) {
          response = await this.$axios.get('admin/pmp-ad/agency-dashboard-detail', {
            headers: {
              userHasGroupId: this.$route.params.userId,
            },
          })
        } else {
          response = await this.$axios.get('/pmp-ads/agency-dashboard-detail')
        }
        this.dataForAgency = response.data.data
      } catch (error) {
        console.error(error.message)
        showToast('Dashboard', error.message, 'danger', 4000)
      } finally {
        this.getVenueDataLoader = false
      }
    },
  },

}
</script>

<style lang="scss" scoped>

</style>

<template>
  <b-card>
    <div
      class=" mx-auto p-0 border-0 "
      style="max-width: 400px; background-color: #fff; border-radius: 10px;; padding: 10px; text-align: center;"
    >
      <!-- Card header with image -->
      <div
        class="card-header p-0"
        style="margin-bottom: 16px; height: 200px;"
      >
        <img
          :src="require('@/assets/images/calendly/image.png')"
          alt="Webinar"
          style="width: 100%; height: 100%; object-fit: cover; background-color: #E8E7FD; border-radius: 10px;"
        >
      </div>
      <!-- Card body with title and description -->
      <div class="card-body p-0 text-left">
        <h5
          class="card-title"
          style="font-size: 20px; font-weight: 600; margin: 10px 0; color: #000;"
        >
          Book a Meeting
        </h5>
        <p
          class="card-text"
          style="font-size: 14px; color: #6b7280; margin-bottom: 15px;"
        >
          Have questions? Book a time with us, and we'll be happy to assist you!
        </p>
      </div>
      <!-- Details section -->
      <div
        class="card-body p-0"
        style="margin-bottom: 20px;"
      >
        <div
          class="d-flex justify-content-between"
          style="padding: 10px 0; color: #000;"
        >
          <!-- Detail item: Date -->
          <div
            class="d-flex align-items-center justify-content-between"
          >
            <div
              class="d-flex align-items-center justify-content-center me-2"
              style="background-color: #E8E7FD; padding: 10px; border-radius: 20%;"
            >
              <feather-icon
                icon="CalendarIcon"
                class="text-primary"
                size="18"
              />
            </div>
            <div class="d-flex flex-column align-items-start ms-2">
              <p style="font-size: 14px; font-weight: 600; margin: 0px 10px;">
                All Week
              </p>
              <p style="font-size: 12px; color: #6b7280; margin: 0px 10px;">
                Date
              </p>
            </div>
          </div>
          <!-- Detail item: Duration -->
          <div
            class="d-flex align-items-center"
            style="padding: 0 10px;"
          >
            <div
              class="d-flex align-items-center justify-content-center me-2"
              style="background-color: #E8E7FD; padding: 10px; border-radius: 20%;"
            >
              <feather-icon
                icon="ClockIcon"
                class="text-primary"
                size="18"
              />
            </div>
            <div class="d-flex flex-column align-items-start">
              <p style="font-size: 14px; font-weight: 600; margin: 0px 10px;">
                30 minutes
              </p>
              <p style="font-size: 12px; color: #6b7280; margin: 0px 10px;">
                Duration
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Button -->
      <div class="card-body p-0">
        <button
          class="btn custom-card-button w-100"
          style="background-color: #6f39ee; color: #fff; padding: 10px; border: none; border-radius: 8px; transition: background-color 0.3s; margin-bottom: 10px;"
          @click="openCalendly"
        >
          Join the event
        </button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  mounted() {
    const script = document.createElement('script')
    const link = document.createElement('link')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    link.href = 'https://assets.calendly.com/assets/external/widget.css'
    script.async = true
    link.rel = 'stylesheet'
    document.body.appendChild(script)
    document.head.appendChild(link)
  },
  methods: {
    openCalendly() {
      const calendlyUrl = process.env.VUE_APP_CALLENDLY_URL
      window.Calendly.initPopupWidget({ url: calendlyUrl })
    },
  },
}
</script>

    <style scoped>
    .custom-card-button:hover {
      background-color: #7c3aed;
    }
    </style>

<template>
  <div>
    <div class="d-flex justify-content-end mt-0" />
    <b-card
      no-body
    >
      <div
        class="m-2"
      >
        <b-row class="d-flex flex-start">
          <span
            class="pl-2"
          >
            <h4 class="text-primary">All Venue(s)</h4>
          </span>
        </b-row>
      </div>
      <b-skeleton-table
        v-if="spinner"
        :rows="paginationObject.limit"
        :columns="5"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <b-table
        v-else
        id="allGroups-table"
        responsive
        outlined
        hover
        selectable
        :per-page="paginationObject.limit"
        :select-mode="selectMode"
        :items="allVenuesList.venues"
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
        :sort-by.sync="sortBy"
        @row-selected="onRowSelected"
      >
        <template #cell(ID)="data">
          <span
            class="d-block text-nowrap text-truncate"
            style="max-width: 150px;"
          >
            {{ data.item.id }}
          </span>
        </template>
        <template #cell(Name)="data">
          <span
            class="d-block text-nowrap text-truncate"
            style="max-width: 150px;"
          >
            {{ data.item.venue_name }}
          </span>
        </template>
        <template
          #cell(Address)="data"
        >
          <span
            class="text-nowrap d-inline-block text-truncate"
            style="max-width: 150px;"
          >
            {{ data.item.address }}
          </span>
        </template>
        <template
          #cell(Email)="data"
        >
          <span
            class="text-nowrap d-inline-block"
            style="max-width: 200px;"
          >
            {{ data.item.contact_email || '--' }}
          </span>
        </template>
        <template
          #cell(Venue_Type)="data"
        >
          <span>
            <span>
              {{ data.item.type.venue_type_name || '--' }}
            </span>
          </span>
        </template>
      </b-table>
    </b-card>
    <CustomPagination
      v-if="allVenuesList.venues"
      :total="paginationObject.total"
      :per-page="paginationObject.limit"
      :page="paginationObject.offset"
      @perPageChanged="perPageChanged"
      @pageChanged="pageChanged"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BTable,
  VBToggle,
  BSkeletonTable,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import CustomPagination from '@/components/common/CustomPagination.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CustomPagination,
    BCard,
    BRow,
    BTable,
    BSkeletonTable,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    isComingFromDashboard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      allVenuesList: {},
      AccountTypes,
      active: false,
      sortBy: 'STATUS',
      searchQuery: '',
      spinner: false,
      allScheduleSlots: [],
      deleteUserId: null,
      paginationObject: {
        offset: 1,
        limit: 5,
        total: 0,
      },
      tableColumns: [
        { key: 'ID' },
        { key: 'Name', label: 'Name' },
        { key: 'Address', label: 'Address' },
        // { key: 'Email', label: 'Email' },
        { key: 'Venue_Type', label: 'Venue Type' },
      ],
      selectMode: 'single',
    }
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },
  async mounted() {
    await this.getAllVenueList(this.paginationObject)
  },
  methods: {
    async getAllVenueList({ offset, limit }) {
      let response
      try {
        this.spinner = true
        if (this.isUserAdmin) {
          response = await this.$axios.get(`admin/pmp-ad/venue-dashboard-table?offset=${offset - 1}&limit=${limit}&search_query=`)
        } else {
          response = await this.$axios.get(`/pmp-ads/venue-dashboard-table?offset=${offset - 1}&limit=${limit}&search_query=`)
        }
        this.allVenuesList = response.data.data
        this.paginationObject.total = response.data.data.total
      } catch (error) {
        console.error('error', error.message)
        showToast('All Venues List', error.response.data.message ? error.response.data.message : error.message, 'danger', 4000)
      } finally {
        this.spinner = false
      }
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllVenueList(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllVenueList(this.paginationObject)
    },
    setPaginationToDefault() {
      this.paginationObject = {
        offset: 1,
        limit: 5,
        total: 0,
      }
    },
    onRowSelected(item) {
      if (this.isUserAdmin) {
        if (this.$route.name === 'simi-network') {
          this.$router.push(`/simi-network/venue/${item[0].id}`)
        } else if (this.$route.name === 'dashboard') {
          this.$router.push(`/dashboard/venue/${item[0].id}`)
        } else {
          this.$router.push({
            name: 'user-single-venue-details',
            params: {
              venueId: item[0].id,
              userId: this.$route.params.userId,
            },
          })
        }
      } else if (!this.isUserAdmin && this.isComingFromDashboard) {
        this.$router.push(`/dashboard/venue/${item[0].id}`)
      } else {
        this.$router.push(`/simi-network/venue/${item[0].id}`)
      }
    },
  },
}
</script>

      <style lang="scss" scoped>
      .per-page-selector {
        width: 90px;
      }

      .invoice-filter-select {
        min-width: 190px;

        ::v-deep .vs__selected-options {
          flex-wrap: nowrap;
        }

        ::v-deep .vs__selected {
          width: 100px;
        }
      }
      </style>

      <style lang="scss">
      @import "@core/scss/vue/libs/vue-select.scss";
      .circular_image {
        border-radius: 40px;
        background-color: #eeedfd;
      }
      </style>

<template>
  <div>
    <b-skeleton-img
      v-if="agencyDashboardLoader"
      no-aspect
      height="300px"
    />
    <b-row
      v-else
    >
      <b-col
        lg="3"
        md="3"
        class="border-primary d-flex flex-column cardVenue"
      >
        <div
          class="mt-1 d-flex flex-column ml-25"
        >
          <div
            v-if="selectedVenue.length === 0 "
            class="text-muted"
          >
            <span class="text-primary">Please Select Venue to Create Campaign</span>
          </div>
          <div
            v-else
          >
            <b-card
              v-for="(tab, index) in selectedVenue"
              :key="index"
              class="listSelectedVenue"
            >
              <span>
                <b-avatar
                  variant="light-info"
                  size="25"
                >
                  <feather-icon
                    size="14"
                    icon="CheckCircleIcon"
                  />
                </b-avatar>
                <span class="pl-25 font-small-2 font-weight-bolder">
                  {{ tab.venue_name }}
                </span>
              </span>
              <b-button
                variant="gradient-danger"
                class="btn-icon"
                size="sm"
                @click="removeVenueFromSelectedVenue(tab)"
              >
                <feather-icon
                  icon="XIcon"
                  size="14"
                />
              </b-button>
            </b-card>
          </div>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :disabled="selectedVenue.length === 0 "
          variant="outline-primary"
          size="sm"
          class="m-2"
          @click="createEditExternalAds"
        >
          Create Campaign</b-button>
      </b-col>
      <b-col
        lg="9"
        md="9"
        class="pr-2 cardVenue"
        @scroll="handleScroll"
      >
        <b-row>
          <b-col
            v-for="(tab, index) in getAllVenuesForAgencyList.allVenues"
            :key="index"
            :title="tab.venue_name"
            lg="4"
            md="6"
          >
            <CardAdvanceMeetup
              :selected-venue="selectedVenue"
              :venue="tab"
              :simi-network="simiNetwork"
              @bookThisVenue="bookThisVenue(tab)"
            />
          </b-col>
        </b-row>
        <b-card
          v-if="OnScrollLoading"
        >
          <b-skeleton
            animation="wave"
            width="85%"
          />
          <b-skeleton
            animation="wave"
            width="55%"
          />
          <b-skeleton
            animation="wave"
            width="70%"
          />
        </b-card>
      </b-col>
    </b-row>
    <CardSelectGroupModal
      ref="external-admin-select-group"
      :simi-network="simiNetwork"
    />
  </div>
</template>

<script>
import {
  BRow, BCol,
  BButton,
  BCard,
  BAvatar,
  BSkeletonImg,
  BSkeleton,
} from 'bootstrap-vue'
import CardAdvanceMeetup from '@/components/common/CardAdvanceMeetup.vue'
import { checkScrollIfonBottom, showToast } from '@/common/global/functions'
import AccountTypes from '@/common/enums/accountTypeEnum'
import CardSelectGroupModal from './CardSelectGroupModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BAvatar,
    CardAdvanceMeetup,
    BSkeletonImg,
    BSkeleton,
    CardSelectGroupModal,
  },
  props: {
    statsData: {
      type: Object,
      default: () => null,
    },
    simiNetwork: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedVenue: [],
      getAllVenuesForAgencyList: { allVenues: [], total: 0 },
      agencyDashboardLoader: false,
      OnScrollLoading: false,
      offset: 1,
      limit: 6,
    }
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },
  async mounted() {
    this.agencyDashboardLoader = true
    await this.getAllVenuesForAgency()
    this.agencyDashboardLoader = false
  },
  methods: {
    bookThisVenue(venue) {
      if (venue.device.length === 0) {
        this.$swal('There is no available device at this venue')
      } else {
        this.selectedVenue.push(venue)
      }
    },
    async handleScroll(event) {
      const bottomOfWindow = checkScrollIfonBottom(event)
      if ((this.getAllVenuesForAgencyList.total / this.limit) > this.offset) {
        if (bottomOfWindow && !this.OnScrollLoading) {
          this.offset += 1
          this.OnScrollLoading = true
          await this.getAllVenuesForAgency()
          this.OnScrollLoading = false
        }
      }
    },
    removeVenueFromSelectedVenue(venue) {
      const index = this.selectedVenue.findIndex(screen => screen.id === venue.id)
      if (index !== -1) {
        this.selectedVenue.splice(index, 1)
      }
    },
    async getAllVenuesForAgency() {
      let res
      try {
        if (this.isUserAdmin) {
          res = await this.$axios.get(`admin/pmp-ad/agency-dashboard-venues?offset=${this.offset - 1}&limit=${this.limit}`, {
            headers: {
              userHasGroupId: this.$route.params.userId || 2,
            },
          })
        } else {
          res = await this.$axios.get(`/pmp-ads/agency-dashboard-venues?offset=${this.offset - 1}&limit=${this.limit}`)
        }
        this.getAllVenuesForAgencyList.allVenues = [
          ...this.getAllVenuesForAgencyList.allVenues,
          ...res.data.data.allVenues,
        ]
        this.getAllVenuesForAgencyList.total = res.data.data.total
      } catch (error) {
        console.error(error.message)
        showToast('Dashboard', error.message, 'danger', 4000)
      }
    },
    createEditExternalAds() {
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES_ON_DASHBOARD', this.selectedVenue)
      if (this.isUserAdmin) {
        if (this.$route.name === 'user') {
          this.$router.push({
            name: 'admin-user-external',
            params: {
              userId: this.$route.params.userId,
            },
          })
        } else {
          this.$refs['external-admin-select-group'].showLocationModal()
        }
      } else if (!this.isUserAdmin && this.simiNetwork) {
        this.$router.push({ name: 'simi-network-pmp-ads-edit' })
      } else {
        this.$router.push({ name: 'dashboard-pmp-ads-edit' })
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.card{
    border: 1px solid rgba(34, 41, 47, 0.125) !important;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
  }
.cardVenue{
    min-height: 720px;
    max-height: 720px;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    display: block;
}
.listSelectedVenue .card-body{
  padding: .5rem;
  display: flex;
  justify-content: space-between;
}

.cardVenue::-webkit-scrollbar {
    width: 6px;
  }
  .cardVenue::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 50px;
  }

  .cardVenue::-webkit-scrollbar-thumb {
    background-color: #4c92fc;
    border-radius: 50px;
  }
</style>

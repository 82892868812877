<template>
  <div>
    <b-row>
      <b-col
        lg="3"
        md="3"
      >
        <b-skeleton-img
          v-if="spinner"
        />
        <StatisticCardWithAreaChart
          v-else
          icon="DollarSignIcon"
          :statistic="dataAdmin ? (dataAdmin.simiTotalRevenue).toFixed(2) : 0"
          statistic-title="Total Earnings by SIMI"
          :chart-data="data.subscribersGained.series"
          color="success"
          style="pointer-events: none; min-height: 295px;"
        />
      </b-col>
      <b-col
        lg="3"
        md="3"
      >
        <b-skeleton-img
          v-if="spinner"
        />
        <StatisticCardWithAreaChart
          v-else
          icon="UsersIcon"
          :statistic="`${dataAdmin ? dataAdmin.businessCount : 0}`"
          statistic-title="Total Subscriptions"
          :chart-data="data.subscribersGained1.series"
          style="pointer-events: none; min-height: 295px;"
        />
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-skeleton-img
          v-if="spinner"
        />
        <CardPmpRefundStat
          v-else
          name="External Refund"
          :data-info="dataAdmin ? dataAdmin.pmpRefunds : null"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="6"
        md="6"
      >
        <b-skeleton-img
          v-if="spinner"
        />
        <CardPmpRefundStat
          v-else
          name="Venue Owner Revenue"
          :data-info="dataAdmin ? dataAdmin.venueEarnings : null"
        />
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-skeleton-img
          v-if="spinner"
        />
        <CardPmpRefundStat
          v-else
          name="Agency Revenue"
          :data-info="dataAdmin ? dataAdmin.agencyEarnings : null"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <b-card>
          <CardAgencyVenueDashboard
            class="p-25 mb-2"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <b-card>
          <ShowVenueList
            class="p-25"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="6"
        md="6"
      >
        <CardAllBusinesses />
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <CardBusinessOnTrialScreens />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="6"
        md="6"
      >
        <CardAllScreens />
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <CardUserNonConfiguredScreens />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import {
  BRow, BCol,
  BCard,
  BSkeletonImg,
} from 'bootstrap-vue'
import CardPmpRefundStat from '@/components/common/CardPmpRefundStat.vue'
import CardAgencyVenueDashboard from '@/components/common/CardAgencyVenueDashboard.vue'
import ShowVenueList from '@/components/simiNetwork/ShowVenueList.vue'
import CardUserNonConfiguredScreens from '@/components/admin/CardUserNonConfiguredScreens.vue'
import CardBusinessOnTrialScreens from '@/components/admin/CardBusinessOnTrialScreens.vue'
import CardAllBusinesses from '@/components/admin/CardAllBusinesses.vue'
import CardAllScreens from '@/components/admin/CardAllScreens.vue'
import { kFormatter } from '@/@core/utils/filter'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    StatisticCardWithAreaChart,
    CardPmpRefundStat,
    CardAgencyVenueDashboard,
    ShowVenueList,
    CardUserNonConfiguredScreens,
    CardBusinessOnTrialScreens,
    CardAllBusinesses,
    CardAllScreens,
    BRow,
    BCol,
    BCard,
    BSkeletonImg,
  },
  data() {
    return {
      dataInfo: {
        id: 1,
        name: 'External Refund',
      },
      dataInfo1: {
        id: 2,
        name: 'Venue Owner Revenue',
      },
      dataInfo2: {
        id: 3,
        name: 'Agency Revenue',
      },
      data: {
        subscribersGained: {
          series: [
            {
              name: 'Subscribers',
              data: [28, 40, 36, 52, 38, 60, 55],
            },
          ],
        },
        subscribersGained1: {
          series: [
            {
              name: 'Subscribers',
              data: [55, 60, 38, 52, 36, 40, 28],
            },
          ],
        },
      },
      dataAdmin: null,
      spinner: false,
    }
  },
  mounted() {
    this.getStatsAdmin()
  },
  methods: {
    kFormatter,
    async getStatsAdmin() {
      try {
        this.spinner = true
        const response = await this.$axios.get('/admin/pmp-ad/admin-earnings-stats')
        this.dataAdmin = response.data.data
      } catch (error) {
        console.error(error.message)
        showToast('Admin Dashboard', error.message, 'danger')
      } finally {
        this.spinner = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

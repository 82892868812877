<template>
  <b-card
    no-body
    class="card-employee-task"
  >
    <b-card-header>
      <b-card-title>Businesses on Trial</b-card-title>
    </b-card-header>
    <!-- body -->
    <b-card-body :class="BusinessesOnTrialList.devices && loading === false? 'noMedia': null">
      <b-skeleton-table
        v-if="loading"
        :rows="5"
        :columns="2"
        :table-props="{ striped: true, tableClass:'skeleton-loading-table' }"
      />
      <div
        v-else-if="!BusinessesOnTrialList.results"
      >
        No Businesses on Trial found
      </div>
      <div v-else>
        <div
          class="d-flex justify-content-between"
        >
          <h5 class="ml-2 text-primary">
            Total Businesses on Trial
          </h5>
          <h5 class="mr-2 text-primary">
            {{ BusinessesOnTrialList.total }}
          </h5>
        </div>
        <div class="px-1">
          <hr class="mb-2">
        </div>
        <div
          class="employee-task"
          @scroll="handleScroll"
        >
          <div
            v-for="(data) in BusinessesOnTrialList.results"
            :key="data.id"
            class="d-flex justify-content-between align-items-center mx-2 mb-1"
            @click="GoToSingleBusiness(data)"
          >
            <b-media no-body>
              <b-media-aside class="mr-75">
                <b-avatar
                  variant="light"
                  :src="data.images ? data.images[0] : ''"
                />
              </b-media-aside>
              <b-media-body>
                <b-link>
                  <h6 class="mb-0 text-primary text-truncate text-class">
                    {{ data.group_name }}
                  </h6>
                </b-link>
              </b-media-body>
            </b-media>
            <div class="d-flex align-items-center">
              <h6 class="text-muted mr-100 mt-2">
                ID-{{ data.id }}
              </h6>
            </div>
          </div>
          <b-skeleton-table
            v-if="OnScrollLoading"
            :rows="1"
            :columns="2"
            :table-props="{ striped: true, tableClass:'skeleton-loading-table' }"
          />
        </div>
      </div>
    </b-card-body>
    <div class="px-1">
      <hr class="mb-2">
    </div>
    <b-button
      variant="link"
      class="links"
      @click="exploreBusinessesOnTrial"
    >
      Open
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BMediaBody, BLink,
  BButton, BAvatar, BSkeletonTable,
  // BImg,
} from 'bootstrap-vue'
import { checkScrollIfonBottom } from '@/common/global/functions'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    // BImg,
    BButton,
    BAvatar,
    BSkeletonTable,
    // VueApexCharts,
  },
  data() {
    return {
      loading: false,
      BusinessesOnTrialList: { results: [], total: 0 },
      limit: 10,
      offset: 1,
      OnScrollLoading: false,
    }
  },
  async mounted() {
    this.loading = true
    await this.loadBusinessesOnTrial()
    this.loading = false
  },
  methods: {
    async loadBusinessesOnTrial() {
      try {
        const response = await this.$store.dispatch('admin/getBusinessTrialList', { offset: this.offset, limit: this.limit, query: '' })
        if (response) {
          const data = await this.$store.getters['admin/getBusinessTrialList']
          this.BusinessesOnTrialList.results = [
            ...this.BusinessesOnTrialList.results,
            ...data.results,
          ]
          this.BusinessesOnTrialList.total = data.total
        }
      } catch (error) {
        console.error('Failed to load businesses:', error)
      }
    },
    async handleScroll(event) {
      const bottomOfWindow = checkScrollIfonBottom(event)
      if ((this.BusinessesOnTrialList.total / 10) > this.offset) {
        if (bottomOfWindow && !this.OnScrollLoading) {
          this.offset += 1
          this.OnScrollLoading = true
          await this.loadBusinessesOnTrial()
          this.OnScrollLoading = false
        }
      }
    },
    GoToSingleBusiness(data) {
      this.$router.push(`/groups/${data.id}`)
    },
    exploreBusinessesOnTrial() {
      this.$router.push('/businessTrial')
    },
  },
}
</script>
    <style scoped>
    .links{
        text-decoration: underline !important;
    }
    .card-employee-task .card-body{
      margin-bottom: 0;
      min-height: 400px !important;
      max-height: 400px !important;
      }
    .employee-task{
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 300px;
      }
    .userMediaImage{
      width: 43px;
      height: 43px;
    }
    .avatar{
      background-color: white;
      border-color: #b8c2cc;
      border-style: solid;
      border-width: thin;
      width: 45px;
      height: 45px;
    }
    .noMedia{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
.employee-task::-webkit-scrollbar {
  width: 6px;
}
.employee-task::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 50px;
}

.employee-task::-webkit-scrollbar-thumb {
  background-color: #4c92fc;
  border-radius: 50px;
}
.text-class{
  max-width: 200px;
}
@media(max-width: 900px){
  .text-class{
  max-width: 150px;
}
}
@media(max-width: 768px){
  .text-class{
  max-width: 170px;
}
}
@media(max-width: 400px){
  .text-class{
  max-width: 120px;
}
}
    </style>

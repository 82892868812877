<template>
  <div>
    <b-card>
      <b-card-title>
        {{ name }}
      </b-card-title>
      <b-row>
        <b-col
          lg="6"
          md="6"
        >
          <statistic-card-horizontal
            icon="DollarSignIcon"
            color="success"
            style="  min-height: 180px !important;"
            :statistic="dataInfo ? `$ ${dataInfo.shared ? Number(dataInfo.shared).toFixed(2) : 0}`: 0"
            :statistic-title="name === 'External Refund' ? 'Refund Given' : 'Revenue Shared'"
          />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <statistic-card-horizontal
            icon="DollarSignIcon"
            style="  min-height: 180px !important;"
            color="warning"
            :statistic="dataInfo ? `$ ${dataInfo.toBeShared ? Number(dataInfo.toBeShared).toFixed(2) : 0}`: 0"
            :statistic-title="name === 'External Refund' ? 'To Be Refunded' : 'To Be Shared'"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@/@core/components/statistics-cards/StatisticCardVertical.vue'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import { kFormatter } from '@/@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    StatisticCardHorizontal,
    BCardTitle,
  },
  props: {
    name: {
      type: String,
      default: () => null,
    },
    dataInfo: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(MemberPermissions.AGENCY)
    },
  },
  methods: {
    kFormatter,
  },
}
</script>

<template>
  <div>
    <b-row>
      <b-col
        lg="6"
        md="6"
      >
        <statistic-card-horizontal
          icon="DollarSignIcon"
          color="primary"
          style="  min-height: 180px !important;"
          :statistic="statsData ? `$ ${statsData.totalAgencyCampaignCoast.toFixed(2)}`: 0"
          statistic-title="Total Campaign Costs"
        />
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <statistic-card-horizontal
          icon="TrendingUpIcon"
          style="  min-height: 180px !important;"
          color="info"
          :statistic="statsData ? `${(statsData.totalAgencyCampaignImpression).toFixed(2)}`: 0"
          statistic-title="Campaign Impressions"
        />
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <statistic-card-horizontal
          icon="DollarSignIcon"
          style="  min-height: 170px !important;"
          color="success"
          :statistic="statsData ? (statsData.agencyEarning.totalEarnings).toFixed(2) : 0"
          statistic-title="Earned Revenue"
        />
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <statistic-card-horizontal
          :icon="isAgency ? 'BoldIcon': 'TrendingUpIcon'"
          style="min-height: 170px !important;"
          color="danger"
          :statistic="statsData ? statsData.highestPaidBrandNameOfAgency : '--'"
          :statistic-title="'Highest Paid Brand'"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@/@core/components/statistics-cards/StatisticCardVertical.vue'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardHorizontal,
  },
  props: {
    statsData: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(MemberPermissions.AGENCY)
    },
  },
}
</script>

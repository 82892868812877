<template>
  <div>
    <b-card
      v-if="isSimmiUser && !isAgency"
    >
      <VenueDashboard />
    </b-card>

    <b-card
      v-if="isSimmiUser && isAgency"
    >
      <AgencyDashboard />
    </b-card>
    <b-row
      v-if="isSimmiUser"
    >
      <b-col
        lg="6"
        md="6"
      >
        <Calendly />
      </b-col>
    </b-row>
    <b-row
      v-if="!isSimmiUser"
    >
      <b-col
        lg="12"
        md="12"
      >
        <CardAdminDashboard
          class="p-25"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCard,
} from 'bootstrap-vue'
// import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import Calendly from '@/components/common/Calendly.vue'
import VenueDashboard from './simiNetworkDashboard/VenueDashboard.vue'
import AgencyDashboard from '@/views/agencyDashboard/index.vue'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import CardAdminDashboard from '@/components/admin/CardAdminDashboard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Calendly,
    VenueDashboard,
    AgencyDashboard,
    CardAdminDashboard,
  },
  data() {
    return {
    }
  },
  computed: {
    isSimmiUser() {
      return this.$store.getters['user/isSimmiUser']
    },
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(MemberPermissions.AGENCY)
    },
  },
}
</script>
<style scoped>
.simi-user-class{
  padding-top: 10px;
  padding-bottom: 10px;
}
.card{
  border: 1px solid rgba(34, 41, 47, 0.125) !important;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.25) !important;
}
</style>
